


























































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Organizaciones extends Vue {
  private nombreWeb: any = "";

  private documentos = [
    {
      nombre: "Comunicado de Elección Nº 15",
      link:
        "url_media/media/documentos/2021/08/certificado-15-pj.pdf"
    },
    {
      nombre: "Comunicado de Elección Nº 16",
      link:
        "url_media/media/documentos/2021/08/comunicado-eleccion-n-16.pdf"
    },
    {
      nombre: "Certificado Provisorio Nº 16 Agrupación Creando Sueños",
      link:
        "url_media/media/documentos/2021/08/certif-provisorio-n-16-agrup-creando-suenos.pdf"
    },
    {
      nombre:
        "Certificado Provisorio Nº 17 Comité de Adelanto Vecinal Centro Histórico de Maipú",
      link:
        "url_media/media/documentos/2021/08/certif-provisorio-n-17-c-adelanto-vecinal-centro-historico-de-maipu.pdf"
    },
    {
      nombre:
        "Certificado Provisorio Nº 18 Comité de Adelanto Comunidad Edificio Tristán Valdés",
      link:
        "url_media/media/documentos/2021/08/certif-provisorio-n-18-c-adelanto-comunidad-edificio-tristan-valdes.pdf"
    },
    {
      nombre:
        "Certificado Provisorio Nº 19 Club Social, Cultural, Deportivo Juan de la Cruz Cerda",
      link:
        "url_media/media/documentos/2021/08/certif-provisorio-n-19-club-soc-cult-deportivo-juan-de-la-cruz-cerda.pdf"
    },
    {
      nombre: "Certificado Provisorio Nº 20 Comité de Vivienda MiguelZaror",
      link:
        "url_media/media/documentos/2021/08/comunicado-eleccion-n-20.pdf"
    },
    {
      nombre: "Comunicado de Elección Nº 21",
      link:
        "url_media/media/documentos/2021/08/comunicado-eleccio-n-n-21.pdf"
    },
    {
      nombre:
        "Comunicado de Elección Nº 21 Comité de Adelanto y Mejoramiento La Glorieta de Maipú",
      link:
        "url_media/media/documentos/2021/08/comunicado-eleccio-n-n-21-1.pdf"
    },
    {
      nombre: "Comunicado de Elección Nº 22",
      link:
        "url_media/media/documentos/2021/08/comunicado-eleccio-n-n-22.pdf"
    },
    {
      nombre: "Comunicado de Elección Nº 23",
      link:
        "url_media/media/documentos/2021/08/COMUNICADO_ELECCION_N_23.pdf"
    },
    {
      nombre: "Comunicado de Elección Nº 24",
      link:
        "url_media/media/documentos/2021/09/comunicado-eleccio-n-n-24.pdf"
    },
    {
      nombre:
        "Certificado Provisorio N° 25 Comite de ADEL.Y MEJ.LA GLORIETA DE MAIPÚ Nº 25",
      link:
        "url_media/media/documentos/2021/09/certif-provisorio-n-25-comite-de-adel-y-mej-la-glorieta-de-maipu.docx"
    },
    {
      nombre: "Comunicado de Elección Nº 26",
      link:
        "url_media/media/documentos/2021/09/comunicado-eleccion-n-26.pdf"
    },
    {
      nombre: "Comunicado de Elección Nº 28",
      link:
        "url_media/media/documentos/2021/09/comunicado-eleccion-n-28.pdf"
    },
    {
      nombre: "Comunicado de Elección Nº 29",
      link:
        "url_media/media/documentos/2021/09/comunicado-eleccion-n-29.pdf"
    },
    {
      nombre: "Comunicado de Elección Nº 30",
      link:
        "url_media/media/documentos/2021/09/comunicado-eleccion-n-30.pdf"
    },
    {
      nombre: "Comunicado de Elección Nº 31",
      link:
        "url_media/media/documentos/2021/09/comunicado-eleccion-n-31.pdf"
    },
    {
      nombre: "Comunicado de Elección Nº 32",
      link:
        "url_media/media/documentos/2021/09/comunicado-eleccion-n-32.pdf"
    },
    {
      nombre: "Comunicado de Elección Nº 33",
      link:
        "url_media/media/documentos/2021/09/comunicado-eleccion-n-33.pdf"
    },
    {
      nombre: "Comunicado de Elección Nº 35",
      link:
        "url_media/media/documentos/2021/09/comunicado-eleccion-n-35.pdf"
    },
    {
      nombre: "Comunicado de Elección Nº 36",
      link:
        "url_media/media/documentos/2021/09/comunicado-eleccion-n-36.pdf"
    },
    {
      nombre: "Certificado de Elección N°37",
      link:
        "url_media/media/documentos/2021/09/comunicado-eleccio-n-n-37.pdf"
    },
    {
      nombre:
        "Certificado Provisorio N°27 Comite de vecinos de DES.ADEL.SEGURIDAD EDUC.FORMACIÓN INTEGRAL, CULTURA VILLA LAS TERRAZAS",
      link:
        "url_media/media/documentos/2021/09/certif-provisorio-n-27-comite-vec-de-des-adel-seguridad-educ-formacion-integral-cultura-villa-las-terrazas.pdf"
    },
    {
      nombre: "Certificado Provisorio N°28 Junta de Vecino Villa Pozo Al Monte",
      link:
        "url_media/media/documentos/2021/09/certif-provisorio-n-28-j-v-villa-pozo-al-monte.pdf"
    },
    {
      nombre:
        "Certificado Provisorio N°29 AGRUP.DE PADRES Y AMIG.DE PAC.CON DISCAP.ASOCIADOS AL SÍNDROME DE DOWN DOWN ARCOÍRIS",
      link:
        "url_media/media/documentos/2021/09/certif-provisorio-n-29-agrup-de-padres-y-amig-de-pac-con-discap-asociados-al-sindrome-de-down-down-arcoiris.pdf"
    },
    {
      nombre: "Certificado Provisorio N°34 Comite Bosque del Sur 3",
      link:
        "url_media/media/documentos/2021/09/certificado-provisorio-n-34-comite-bosque-del-sur-3.pdf"
    },
    {
      nombre: "Ley 21.146",
      link:
        "url_media/media/documentos/2021/09/ley-21-146-3.xlsx"
    }
  ];
  private mounted() {
    document.title = "Ley 21.146 - Maipú Sustentable";
  }

  private toLink(item: any) {
    if (item && item != "") {
      window.open(item, "_blank");
    } else {
      return;
    }
  }
}










import { Component, Vue } from "vue-property-decorator";
import Organizaciones from "@/components/Municipalidad/Organizaciones.vue";

@Component({
  components: {
    Organizaciones
  }
})
export default class OrganizacionesView extends Vue {}
